* {
  box-sizing: border-box;
}

.walletPage {
  padding: 20px;
}

.walletTitle,
.walletBalance {
  margin: auto;
  width: fit-content;
}
