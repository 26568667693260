.userPanle {
  padding: 20px;
}

.listCard {
  display: flex;
  column-gap: 8px;
  row-gap: 16px;
  flex-wrap: wrap;
  justify-content: center;
}

.listWalletTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.createWalletButton {
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
}

.MuiToolbar-root {
  justify-content: center;
}

.MuiTablePagination-spacer {
  display: none;
}
